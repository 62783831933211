import Vue from 'vue'
import { debounce } from '@simpl/core/utils/core'
import { apolloProvider } from '@simpl/core/plugins/apollo'
import { UPDATE_CONTENT_TREE } from '@simpl/cms/graphql'
import store from '@simpl/core/plugins/store'
import { app } from '@simpl/core/init-app'
import treeDiff from './tree-diff'
import { MUTATIONS } from '@simpl/cms/store/consts'

let running = false

export function scheduleUpdateQueue () {
  checkDirty()
}

function _checkDirty () {
  const { serverContent, content } = store.state.cms

  const diffResult = treeDiff(serverContent, content)

  store.commit('cms/setDirty', !!diffResult)

  if (!diffResult) return

  Vue.nextTick(processUpdateQueue)
}

async function _processUpdateQueue () {
  if (running) {
    scheduleUpdateQueue()
    return
  }

  running = true

  const { serverContent, content } = store.state.cms

  const diffResult = treeDiff(serverContent, content)

  store.commit('forcePersist')

  if (!diffResult) {
    running = false
    return
  }

  store.commit('cms/setContentUpdating', true)

  try {
    const updateResult = await apolloProvider.defaultClient.mutate({
      mutation: UPDATE_CONTENT_TREE,

      fetchPolicy: 'no-cache',

      variables: {
        parent_id: diffResult.id,
        trees: diffResult.children || []
      }
    })

    const updatedSubTree = updateResult.data.updateContentTree
    store.commit(`cms/${MUTATIONS.PATCH_CONTENT}`, updatedSubTree)
  } catch (e) {
    await app.$confirm({
      x: 'center',
      y: 'center',
      message: app.$t('cms.message.updateError'),
      buttons: [{
        text: app.$t('core.global.reload'),
        answer: true
      }]
    })

    location.reload(true)
  }

  store.commit('cms/setContentUpdating', false)

  _checkDirty()

  running = false
}

const processUpdateQueue = debounce(_processUpdateQueue, 2000)
const checkDirty = debounce(_checkDirty, 200)
