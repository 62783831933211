import { ContentTree } from '@simpl/cms/types'
import store from '@simpl/core/plugins/store'
import { scheduleUpdateQueue } from './update-queue'

export default function removeContent (id: number | string) {
  const parent = getParent(store.state.cms.content, String(id))
  if (!parent) return

  parent._changedByUser = true

  const index = parent.children.findIndex(c => String(c.id) === String(id))
  parent.children.splice(index, 1)

  scheduleUpdateQueue()
}

function getParent (parent: ContentTree, id: string): ContentTree | undefined {
  const children = parent.children || []
  const hasValidChild = children.find(c => String(c.id) === id)
  if (hasValidChild) return parent

  for (let i = 0; i < children.length; i++) {
    const parent = getParent(children[i], id)
    if (parent) return parent
  }
}
