





























import Vue from 'vue'
import { CMSComponentProperty } from '@simpl/cms-components'
import * as PropertyDrawerComponents from '../property-drawers'
import EditorGroup from './EditorGroup.vue'
import TrackingSensitiveMarker from '../property-drawers/enhancements/TrackingSensitiveMarker.vue'
import TrackingSensitiveOverlay from '../property-drawers/enhancements/TrackingSensitiveOverlay.vue'
import { HAS_TRACKINGS } from '@simpl/tracking-evaluation/graphql'
import { Query } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'PropertyDrawerGroup',

  components: { ...PropertyDrawerComponents, EditorGroup, TrackingSensitiveOverlay, TrackingSensitiveMarker },

  props: {
    title: String,
    component: Object as () => Vue,
    props: Array as () => CMSComponentProperty[],
    disabled: Boolean
  },

  data () {
    return {
      loading: 0,
      hasTrackings: false
    }
  },

  computed: {
    hasTrackingSensitiveProperty (): boolean {
      return this.props.some(prop => prop.trackingSensitive)
    }
  },

  apollo: {
    hasTrackings: {
      query: HAS_TRACKINGS,

      fetchPolicy: 'no-cache',

      skip (): boolean {
        return !this.component || !this.hasTrackingSensitiveProperty
      },

      variables (): Record<string, any> {
        return {
          key: (this.component as any).content.id
        }
      },

      update (result: Query): Boolean {
        return result.hasTrackings!
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    typeToComponentName (type: string, component?: Vue) {
      if (component) {
        return 'PropertyDrawerCustomComponent'
      }
      const capitalized = type.split(/-/).map(p => p.charAt(0).toUpperCase() + p.slice(1).toLowerCase()).join('')
      const name = `PropertyDrawer${capitalized}`

      if ((this.$options.components || {})[name]) {
        return name
      }

      return 'PropertyDrawerMissingTypeDrawer'
    }
  }
})
