import gql from 'graphql-tag'

export const RUN_EVALUATIONS = gql`query RUN_EVALUATIONS (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    runEvaluations (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            run {
                id
            }
            identifier
            type
            template
            target
            is_public
            properties
            trackingVisualizations {
                id
            }
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const TRACKING_VISUALIZATIONS = gql`query TRACKING_VISUALIZATIONS (
    $module_ids: [ID!],
    $created_at: DateRange,
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    trackingVisualizations (
        created_at: $created_at, 
        filter: $filter, 
        first: $first, 
        module_ids: $module_ids,
        page: $page, 
        orderBy: $orderBy
    ) {
        data {
            id
            identifier
            type
            properties
            items {
                id
                run {
                    id
                }
                module {
                    id
                }
                content {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                content_headline {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                objective
                site
                key
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const HAS_TRACKINGS = gql`query HAS_TRACKINGS ($key: ID!) {
    hasTrackings(key: $key)
}`

export const TRACKING_VISUALIZATION = gql`query TRACKING_VISUALIZATION ($id: ID!) {
    trackingVisualization(id: $id) {
        id
        identifier
        type
        properties
        items {
            id
            run {
                id
            }
            module {
                id
            }
            content {
                id
                type
                data
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            content_headline {
                id
                type
                data
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
            }
            objective
            site
            key
            properties
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        texts {
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const HAS_TRACKING_VISUALIZATION = gql`query HAS_TRACKING_VISUALIZATION ($id: ID!) {
    trackingVisualization(id: $id) {
        id
    }
}`

export const TRACKING_VISUALIZATION_ITEM = gql`query TRACKING_VISUALIZATION_ITEM ($id: ID!) {
    trackingVisualizationItem(id: $id) {
        id
        tracking_visualizations {
            id
        }
        run {
            id
        }
        module {
            id
        }
        content {
            id
            type
            data
            properties
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        content_headline {
            id
            type
            data
            properties
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        objective
        site
        key
        trackings {
            id
            session_token
            type
            objective
            site
            status
            score
            key
            data
            properties
            session {
                token
            }
            texts {
                identifier
                languagecode
                text
            }
            files {
                id
            }
            created_at
            updated_at
        }
        properties
        texts {
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const TRACKING_VISUALIZATION_ITEMS = gql`query TRACKING_VISUALIZATION_ITEMS (
    $created_at: DateRange,
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    trackingVisualizationItems (
        created_at: $created_at,
        filter: $filter,
        first: $first,
        page: $page,
        orderBy: $orderBy
    ) {
        data {
            id
            run {
                id
            }
            module {
                id
            }
            content {
                id
                type
                data
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            content_headline {
                id
                type
                data
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            objective
            site
            key
            trackings {
                id
                session_token
                type
                objective
                site
                status
                score
                key
                data
                properties
                session {
                    token
                }
                texts {
                    identifier
                    languagecode
                    text
                }
                files {
                    id
                }
                created_at
                updated_at
            }
            properties
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const TRACKING_VISUALIZATION_ITEMS_ID_ONLY = gql`query TRACKING_VISUALIZATION_ITEMS_ID_ONLY (
    $created_at: DateRange,
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    trackingVisualizationItems (
        created_at: $created_at,
        filter: $filter,
        first: $first,
        page: $page,
        orderBy: $orderBy
    ) {
        data {
            id
        }
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}`

export const CREATE_RUN_EVALUATION = gql`mutation CREATE_RUN_EVALUATION ($data: NewRunEvaluationInput!) {
    createRunEvaluation(data: $data) {
        id
    }
}`

export const CREATE_TRACKING_VISUALIZATION = gql`mutation CREATE_TRACKING_VISUALIZATION ($data: NewTrackingVisualizationInput!) {
    createTrackingVisualization(data: $data) {
        id
        items {
            id
        }
    }
}`

export const CREATE_TRACKING_VISUALIZATION_ITEM = gql`mutation CREATE_TRACKING_VISUALIZATION_ITEM ($data: NewTrackingVisualizationItemInput!) {
    createTrackingVisualizationItem(data: $data) {
        id
    }
}`

export const UPDATE_RUN_EVALUATION = gql`mutation UPDATE_RUN_EVALUATION ($data: UpdateRunEvaluationInput!) {
    updateRunEvaluation(data: $data) {
        id
    }
}`

export const RUN_EVALUATION = gql`query RUN_EVALUATION ($id: ID!) {
    runEvaluation(id: $id) {
        id
        run {
            id
            checkin_type
            dedicated_waves
            modules {
                id
                texts {
                    id
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                id
                identifier
                languagecode
                text
            }
            type
        }
        identifier
        type
        template
        target
        is_public
        properties
        trackingVisualizations {
            id
            identifier
            items {
                id
                run {
                    id
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                module {
                    id
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                content {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                    created_at
                    updated_at
                }
                content_headline {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                objective,
                site,
                key,
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            type
            properties
            texts {
                id
                identifier
                languagecode
                text
            }
            
            created_at
            updated_at
        }
        texts {
            id
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const RUN_EVALUATION_WITH_DETAILED_MODULES = gql`query RUN_EVALUATION_WITH_DETAILED_MODULES ($id: ID!) {
    runEvaluation(id: $id) {
        id
        run {
            id
            checkin_type
            dedicated_waves
            modules {
                id
                identifier
                duration
                type
                tracking_type
                languagecode
                status
                active
                created_at
                updated_at
                tags {
                    id,
                    category {
                        id
                        identifier
                    },
                    identifier,
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }

                texts {
                    id
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                id
                identifier
                languagecode
                text
            }
            type
        }
        identifier
        type
        template
        target
        is_public
        properties
        trackingVisualizations {
            id
            identifier
            items {
                id
                run {
                    id
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                module {
                    id
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                content {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                    created_at
                    updated_at
                }
                content_headline {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                objective,
                site,
                key,
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            type
            properties
            texts {
                id
                identifier
                languagecode
                text
            }

            created_at
            updated_at
        }
        texts {
            id
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const RUN_EVALUATION_WITH_TRACKINGS = gql`query RUN_EVALUATION_WITH_TRACKINGS ($id: ID!) {
    runEvaluation(id: $id) {
        id
        run {
            id
            checkin_type
            dedicated_waves
            modules {
                id
                texts {
                    id
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                id
                identifier
                languagecode
                text
            }
        }
        identifier
        type
        template
        target
        is_public
        properties
        trackingVisualizations {
            id
            identifier
            items {
                id
                content {
                    id
                    type
                    data
                    properties
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                content_headline {
                    id
                    type
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                run {
                    id
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                module {
                    id
                    texts {
                        identifier
                        languagecode
                        text
                    }
                }
                objective,
                site,
                key,
                trackings {
                    created_at
                    data
                    id
                    key
                    objective
                    properties
                    score
                    session_token
                    site
                    status
                    texts {
                        created_at
                        head
                        id
                        identifier
                        languagecode
                        text
                        updated_at
                    }
                    type
                },
                properties
                texts {
                    identifier
                    languagecode
                    text
                }
                created_at
                updated_at
            }
            type
            properties
            texts {
                id
                identifier
                languagecode
                text
            }

            created_at
            updated_at
        }
        texts {
            id
            identifier
            languagecode
            text
        }
        created_at
        updated_at
    }
}`

export const UPDATE_TRACKING_VISUALIZATION = gql`mutation UPDATE_TRACKING_VISUALIZATION ($data: UpdateTrackingVisualizationInput!) {
    updateTrackingVisualization(data: $data) {
        id
    }
}`

export const UPDATE_TRACKING_VISUALIZATION_ITEM = gql`mutation UPDATE_TRACKING_VISUALIZATION_ITEM ($data: UpdateTrackingVisualizationItemInput!) {
    updateTrackingVisualizationItem(data: $data) {
        id
    }
}`

export const DELETE_RUN_EVALUATION = gql`mutation DELETE_RUN_EVALUATION ($id: ID!) {
    deleteRunEvaluation(id: $id) {
        id
    }
}`

export const DELETE_TRACKING_VISUALIZATION = gql`mutation DELETE_TRACKING_VISUALIZATION ($id: ID!) {
    deleteTrackingVisualization(id: $id) {
        id
    }
}`

export const DELETE_TRACKING_VISUALIZATION_ITEM = gql`mutation DELETE_TRACKING_VISUALIZATION_ITEM ($id: ID!) {
    deleteTrackingVisualizationItem(id: $id) {
        id
    }
}`
