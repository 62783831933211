













































import Vue from 'vue'
import removeContent from '@simpl/cms-components/utils/remove-content'
import PropertyDrawerGroup from './PropertyDrawerGroup.vue'
import { CMSComponentPropertyGroup } from '@simpl/cms-components'
import { ContentTree } from '@simpl/cms/types'
import { deleteConfirm } from '@simpl/core/utils/message'

const capitalize = (v: string) => v.charAt(0).toUpperCase() + v.slice(1)

export default Vue.extend({
  name: 'PropertyDrawer',

  components: {
    PropertyDrawerGroup
  },

  data () {
    return {
      availableTextFormats: ['bold', 'italic', 'underline'],
      textAligns: ['left', 'center', 'right'],
      textAlign: 'left',
      textFormats: [] as string[],
      listFormats: ['unordered', 'ordered'],
      listFormat: null! as string
    }
  },

  computed: {
    component (): Vue & { content: ContentTree } {
      return this.$store.getters['cms/selectedComponent']
    },
    // fixme: fix type
    componentProperties (): CMSComponentPropertyGroup[] {
      return (this.component?.$options.cms?.props || (() => ([]))).call(this.component)
    },
    hideDeleteButton (): boolean {
      return !!this.component?.$options.cms?.hideDeleteButton
    }
  },

  methods: {
    capitalize,
    removeSelectedComponent () {
      deleteConfirm(this, this.$t('cms.component') as string).then(answer => {
        if (answer) removeContent((this.component as any).content.id)
      })
    }
  }
})
