import { ContentTree } from '@simpl/cms/types'
import { hasOwn } from '@simpl/core/utils'
import { CreateTextInput, CreateTextsRelation, Text, UpdateContentTreeInput } from '@simpl/core/types/graphql'

const keysOfInterest: (keyof ContentTree)[] = ['id', 'type', 'data', 'properties']
const existingIds: Set<string> = new Set<string>()
let diffFound = false

type ExtendedUpdateType = UpdateContentTreeInput & {
  _hasChanged?: boolean
  _wasAdded?: boolean
}

export default function treeDiff (prev?: ContentTree, cur?: ContentTree): UpdateContentTreeInput | null {
  existingIds.clear()
  diffFound = false
  const diff = _treeDiff(prev, cur)

  if (!diffFound) {
    return null
  }

  return getActualDiffParent(diff!)
}

function _treeDiff (prev?: ContentTree, cur?: ContentTree, forceHasChanged = false): ExtendedUpdateType | null {
  let key: keyof ContentTree
  const diff: Record<string, any> = {}

  if (cur) {
    existingIds.add(String(cur.id))
  }

  if (!prev && cur) {
    diffFound = true

    return {
      _wasAdded: true,
      _hasChanged: true,
      id: !cur.isLocal ? String(cur.id) : null,
      type: cur.type,
      properties: cur.properties ? JSON.stringify(cur.properties) : null,
      data: cur.data ? JSON.stringify(extractPrivateProperties(cur.data)) : null,
      children: cur.children.map((c) => _treeDiff(undefined, c)!),
      texts: diffTexts([], cur.texts || []) || {}
    }
  }

  if (!cur && prev) {
    diffFound = true

    return {
      id: prev.id,
      deleted: true,
      _hasChanged: true
    }
  }

  if (!cur && !prev) {
    diffFound = false

    return null
  }

  for (key of keysOfInterest) {
    if (key !== 'data') {
      if (
        (!hasOwn(prev, key) && typeof cur![key] !== 'undefined' && cur![key] !== null) ||
        JSON.stringify(prev![key]) !== JSON.stringify(cur![key])
      ) {
        diffFound = true
        diff._hasChanged = true
        if (key === 'properties') {
          diff[key] = JSON.stringify(cur![key])
        } else {
          diff[key] = cur![key]
        }
      }
    } else {
      const prevData = JSON.stringify(extractPrivateProperties(prev![key] || {}))
      const curData = JSON.stringify(extractPrivateProperties(cur![key] || {}))
      if (
        (!hasOwn(prev, key) && typeof cur![key] !== 'undefined' && cur![key] !== null) ||
        prevData !== curData
      ) {
        diffFound = true
        diff._hasChanged = true
        diff[key] = curData
      }
    }
  }

  const prevTexts = prev!.texts || []
  const curTexts = cur!.texts || []

  const texts = diffTexts(prevTexts, curTexts)
  if (texts) {
    diffFound = true
    diff._hasChanged = true
    diff.texts = texts
  }

  const prevChildren = prev!.children || []
  const curChildren = cur!.children || []

  let children: (ExtendedUpdateType | null)[] = []

  if (!prevChildren.length) {
    addChildDiffs(children, 0, curChildren.length - 1, curChildren)
  } else if (!curChildren.length) {
    addChildDeletions(children, 0, prevChildren.length - 1, prevChildren)
  } else {
    children = _diffChildren(prevChildren, curChildren)
  }

  if (children.length) {
    diff.children = children
  }

  if (!cur!.isLocal || forceHasChanged) diff.id = cur!.id

  if (forceHasChanged) {
    diffFound = true
    diff._hasChanged = true
  }

  return Object.keys(diff).length ? diff as any : null
}

function extractPrivateProperties (obj: Record<string, any>) {
  const keys = Object.keys(obj)
  const result: Record<string, any> = {}
  for (const key of keys) {
    if (key.startsWith('_')) continue

    if (isObject(obj[key])) {
      result[key] = extractPrivateProperties(obj[key])
    } else {
      result[key] = obj[key]
    }
  }

  return result
}

function isObject (v: any) {
  return typeof v === 'object' && v !== null && !Array.isArray(v)
}

function diffTexts (oldTexts: Text[], newTexts: Text[]): CreateTextsRelation | null {
  const fallback = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'

  const created: CreateTextInput[] = []
  const deleted: string[] = []

  /*
  for (const text of newTexts) {
    const oldText = getText(oldTexts, text.identifier, text.languagecode)
    if (!oldText) {
      const fallbackText = getText(newTexts, text.identifier, fallback)
      if (!fallbackText) {
        newTexts.push({
          text: text.text,
          identifier: text.identifier,
          languagecode: fallback
        } as any)
      }
    }
  }
  */

  for (const text of newTexts) {
    const oldText = getText(oldTexts, text.identifier, text.languagecode)
    if (!oldText || (text.text !== oldText.text)) {
      created.push({
        identifier: text.identifier,
        languagecode: text.languagecode,
        text: text.text
      })
    }
  }

  for (const text of oldTexts) {
    if (!getText(newTexts, text.identifier, text.languagecode)) {
      deleted.push(text.id)
    }
  }

  if (!created.length && !deleted.length) return null

  const result: CreateTextsRelation = {}
  if (created.length) result.create = created
  if (deleted.length) result.delete = deleted

  return result
}

function getText (texts: Text[], identifier: string, language?: string | null): Text | null {
  return texts.find(t => (t!.languagecode === language) && (t!.identifier === identifier))!
}

function createKeyToOldIdx (
  children: ContentTree[],
  beginIdx: number,
  endIdx: number
): Record<any, any> {
  let i
  let key
  const map: Record<any, any> = {}
  for (i = beginIdx; i <= endIdx; ++i) {
    key = children[i]?.id
    if (!isUndefined(key)) map[key] = i
  }
  return map
}

function _diffChildren (oldCh: ContentTree[], newCh: ContentTree[]) {
  let oldStartIdx = 0
  let newStartIdx = 0
  let oldEndIdx = oldCh.length - 1
  let oldStartContent = oldCh[0]
  let oldEndContent = oldCh[oldEndIdx]
  let newEndIdx = newCh.length - 1
  let newStartContent = newCh[0]
  let newEndContent = newCh[newEndIdx]
  let contentToMove: ContentTree
  let oldKeyToIdx: Record<any, any> = undefined!
  let idxInOld
  const childDiffs: (ExtendedUpdateType | null)[] = new Array(oldCh.length).fill(null)

  while (oldStartIdx <= oldEndIdx && newStartIdx <= newEndIdx) {
    if (isUndefined(oldStartContent)) {
      oldStartContent = oldCh[++oldStartIdx]
    } else if (isUndefined(oldEndContent)) {
      oldEndContent = oldCh[--oldEndIdx]
    } else if (sameContent(oldStartContent, newStartContent)) {
      childDiffs[newStartIdx] = _treeDiff(oldStartContent, newStartContent)

      oldStartContent = oldCh[++oldStartIdx]
      newStartContent = newCh[++newStartIdx]
    } else if (sameContent(oldEndContent, newEndContent)) {
      childDiffs[newEndIdx] = _treeDiff(oldEndContent, newEndContent)

      oldEndContent = oldCh[--oldEndIdx]
      newEndContent = newCh[--newEndIdx]
    } else if (sameContent(oldStartContent, newEndContent)) { // Content moved 1 position right
      childDiffs[newEndIdx] = _treeDiff(oldStartContent, newEndContent, true)

      oldStartContent = oldCh[++oldStartIdx]
      newEndContent = newCh[--newEndIdx]
    } else if (sameContent(oldEndContent, newStartContent)) { // Content moved 1 position left
      childDiffs[newStartIdx] = _treeDiff(oldEndContent, newStartContent, true)

      oldEndContent = oldCh[--oldEndIdx]
      newStartContent = newCh[++newStartIdx]
    } else {
      // childDiffs[newStartIdx] = _treeDiff(oldEndContent, newEndContent)

      if (isUndefined(oldKeyToIdx)) oldKeyToIdx = createKeyToOldIdx(oldCh, oldStartIdx, oldEndIdx)
      idxInOld = oldKeyToIdx[newStartContent.id]

      if (isUndefined(idxInOld)) {
        childDiffs[newStartIdx] = _treeDiff(undefined, newStartContent, true)
        childDiffs[newStartIdx]!.deletionId = oldStartContent.id
      } else {
        contentToMove = oldCh[idxInOld]
        if (sameContent(contentToMove, newStartContent)) {
          oldCh[idxInOld] = undefined!
          childDiffs[newStartIdx] = _treeDiff(contentToMove, newStartContent, true)
        } else {
          childDiffs[newStartIdx] = _treeDiff(undefined, newStartContent, true)
          childDiffs[newStartIdx]!.deletionId = contentToMove.id
        }
      }

      newStartContent = newCh[++newStartIdx]
    }

    if (oldStartIdx > oldEndIdx) {
      addChildDiffs(childDiffs, newStartIdx, newEndIdx, newCh)
    } else if (newStartIdx > newEndIdx) {
      addChildDeletions(childDiffs, oldStartIdx, oldEndIdx, oldCh)
    }
  }

  return childDiffs.filter(Boolean)
}

function addChildDiffs (
  diffs: (ExtendedUpdateType | null)[],
  startIdx: number, endIdx: number,
  ch: ContentTree[]
) {
  if (startIdx <= endIdx) {
    diffFound = true
  }

  for (; startIdx <= endIdx; ++startIdx) {
    diffs.splice(startIdx, 0, _treeDiff(undefined, ch[startIdx]))
  }
}

function addChildDeletions (
  diffs: (ExtendedUpdateType | null)[],
  startIdx: number, endIdx: number,
  ch: ContentTree[]
) {
  if (startIdx <= endIdx) {
    diffFound = true
  }

  for (; startIdx <= endIdx; ++startIdx) {
    diffs.splice(startIdx, 0, _treeDiff(ch[startIdx], undefined))
  }
}

function getActualDiffParent (diff: UpdateContentTreeInput) {
  _fixInvalidDeletions(diff, diff)
  const result = _traverseDiff(diff)
  return result ? _cleanUpDiff(result) : null
}

function _fixInvalidDeletions (root: ExtendedUpdateType, diff: ExtendedUpdateType) {
  let hasMovedChild = false
  if (diff.children) {
    diff.children = diff.children.filter(c => {
      if (c!.deleted && existingIds.has(String(c!.id))) {
        hasMovedChild = true
        _findAndMarkDiffAsChanged(root, String(c!.id))
        return false
      }
      return true
    })

    diff.children.forEach(c => _fixInvalidDeletions(root, c!))
  }

  if (hasMovedChild) {
    diff._hasChanged = true
  }
}

function _traverseDiff (diff: ExtendedUpdateType, parent?: ExtendedUpdateType): UpdateContentTreeInput | null {
  const childDiffParents = (diff.children || []).map(c => c && _traverseDiff(c, diff)).filter(Boolean)

  if (diff.deletionId && existingIds.has(String(diff.deletionId))) {
    delete diff.deletionId
  }

  const hasChanged = diff._hasChanged

  if (diff._wasAdded) {
    return parent!
  }

  if (!childDiffParents.length) {
    return hasChanged ? parent! : null
  } else if (childDiffParents.length === 1) {
    return childDiffParents[0]!.id ? childDiffParents[0] : diff
  }

  return diff.id ? diff : parent!
}

function _findAndMarkDiffAsChanged (diff: ExtendedUpdateType, id: string) {
  if (String(diff.id) === id && !diff.deleted) {
    diffFound = true
    diff._hasChanged = true

    return
  }

  diff.children?.forEach(c => _findAndMarkDiffAsChanged(c!, id))
}

function _cleanUpDiff (tree: UpdateContentTreeInput) {
  if (!_hasNestedChange(tree)) {
    delete tree.children
  } else {
    tree.children?.forEach(c => _cleanUpDiff(c as any))
  }

  delete (tree as any)._hasChanged
  delete (tree as any)._wasAdded

  return tree
}

function _hasNestedChange (tree: ExtendedUpdateType): boolean {
  return tree._hasChanged || (
    tree.children
      ? tree.children.reduce<boolean>((a, c) => a || _hasNestedChange(c as any), false)
      : false
  )
}

function isUndefined (v: any): v is undefined {
  // eslint-disable-next-line
  return v === void 0
}

function sameContent (o: ContentTree, v: ContentTree) {
  return (String(o.id) === String(v.id))
}
