



































































import Vue from 'vue'
import LayoutEditor from './layout-editor'
import PropertyDrawer from './PropertyDrawer.vue'
import AssetUploader from '../AssetUploader.vue'
import { ContentTree } from '../../types'

export default Vue.extend({
  name: 'ToolView',

  components: {
    LayoutEditor,
    PropertyDrawer,
    AssetUploader
  },

  data: function () {
    return {
      tabs: [{
        icon: 'mdi-cog',
        text: 'settings'
      }, {
        icon: 'mdi-brush',
        text: 'layoutPanel'
      }],

      tab: null! as number
    }
  },

  computed: {
    showAssetUploader (): boolean {
      return this.$store.state.cms._dragDropFileCount === 1
    },
    component (): Vue & { content: ContentTree } {
      return this.$store.getters['cms/selectedComponent']
    }
  }
})
