

































import Vue from 'vue'

export default Vue.extend({
  name: 'TrackingSensitiveOverlay',

  data () {
    return {
      absolute: true,
      overlay: true,
      loading: 0
    }
  }
})
